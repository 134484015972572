import React from "react";
import "../styles/creative.css";
import "../styles/global.css";
import LegalLayout from "../components/layouts/LegalLayout";
import charte from "../assets/md/charte-de-confidentialite";

export default class extends React.Component {
  render() {
    return <LegalLayout text={charte} />;
  }
}
